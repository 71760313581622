// This file is not a Redux type file
// It is used to generate the node objects in the form
// A node is equivalent of a field in the generated questionaire

import { NODETYPE_SELECTMANY, NODETYPE_SELECTONE, NODETYPE_GEO, NODETYPE_IMAGE, NODETYPE_NUMBER, NODETYPE_TEXT, NODETYPE_OPTION } from '../../constants'
import { walk } from 'react-sortable-tree';


// Creates form nodes of the required structure of the back end
export function generateNode({ type, description, id, parentOption, parentField }) {
    try {
        switch (type) {
            case NODETYPE_SELECTMANY:
                return generateSelectManyNode(id, description, parentOption, parentField)
            case NODETYPE_SELECTONE:
                return generateSelectOneNode(id, description, parentOption, parentField)
            case NODETYPE_GEO:
                return generateGeoNode(id, description, parentOption, parentField)
            case NODETYPE_IMAGE:
                return generateImageNode(id, description, parentOption, parentField)
            case NODETYPE_NUMBER:
                return generateNumberNode(id, description, parentOption, parentField)
            case NODETYPE_TEXT:
                return generateTextNode(id, description, parentOption, parentField)
            default:
                throw new Error('Node type not recognised: ' + type)
        }
    } catch(err) {
        throw err
    }
}


function generateSelectManyNode(id, description, parentOption, parentField) {
    const selectManyNode = generateBasicNode(id, description, parentOption, parentField)
    selectManyNode.type = NODETYPE_SELECTMANY
    selectManyNode.options = []
    return selectManyNode
}

function generateSelectOneNode(id, description, parentOption, parentField) {
    const selectOneNode = generateBasicNode(id, description, parentOption, parentField)
    selectOneNode.type = NODETYPE_SELECTONE
    selectOneNode.options = []
    return selectOneNode
}

function generateGeoNode(id, description, parentOption, parentField) {
    const geoNode = generateBasicNode(id, description, parentOption, parentField)
    geoNode.type = NODETYPE_GEO
    return geoNode
}

function generateImageNode(id, description, parentOption, parentField) {
    const imageNode = generateBasicNode(id, description, parentOption, parentField)
    imageNode.type = NODETYPE_IMAGE
    return imageNode
}

function generateTextNode(id, description, parentOption, parentField) {
    const textNode = generateBasicNode(id, description, parentOption, parentField)
    textNode.type = NODETYPE_TEXT
    return textNode
}

function generateNumberNode(id, description, parentOption, parentField) {
    const numberNode = generateBasicNode(id, description, parentOption, parentField)
    numberNode.type = NODETYPE_NUMBER
    return numberNode
}

function generateBasicNode(id, description, parentOption, parentField) {
    const basicNode = {
        id,
        label_display: description,
        name: '' + description + id,
        img: {},
        options: [],
        required: true,
        disabled: false,
        condition: {
            option: parentOption,
            field: parentField
        }
    }
    return basicNode
}


export function generateOption(optionId, fieldId, description) {
    return {
        option_id: optionId,
        field_id: fieldId,
        label: '' + description +  String(fieldId) + String(optionId),
        img: {},
        label_display: description
    }
}


export function isField(myObj) {
    return myObj.hasOwnProperty('type') && myObj.hasOwnProperty('id') && !myObj.hasOwnProperty('option_id')
}

export function isOption(myObj) {
    return myObj.hasOwnProperty('option_id') && myObj.hasOwnProperty('field_id')
}

// ensure child is a field before checking parent
export function isParentRoot(child) {
    return child.condition &&
        !child.condition.hasOwnProperty('option') && !child.condition.hasOwnProperty('field')
}

// ensure child is a field before checking parent
export function isParentField(child) {
    return child.condition &&
        child.condition.hasOwnProperty('option') && child.condition.hasOwnProperty('field') &&
        child.condition.option === null
}

// ensure child is a field before checking parent
export function isParentOption(child) {
    return child.condition &&
        child.condition.hasOwnProperty('option') && child.condition.hasOwnProperty('field') &&
        child.condition.option !== null && child.condition.field !== null
}

export function getField(fieldId, fieldAndOptionArray) {
    const myField = fieldAndOptionArray.find(fieldAndOption => fieldAndOption.id === fieldId)
    if (typeof myField === 'undefined') {
        throw new Error('field id not found error: ' + fieldId)
    } else {
        return myField
    }
}

export function getOption(optionId, fieldId, fieldAndOptionArray) {
    const myOption = fieldAndOptionArray.find(fieldAndOption => fieldAndOption.field_id === fieldId && fieldAndOption.option_id === optionId)
    if (typeof myOption === 'undefined') {
        throw new Error('option id not found error: ' + optionId + ' not found in field: ' + fieldId)
    } else {
        return myOption
    }
}


// Create an RST node for the UI
export function createUiTreeNode (uiNodeType, nextNodeId) {
    try {
        switch (uiNodeType) {
            case NODETYPE_SELECTMANY:
                return {
                    title: NODETYPE_SELECTMANY,
                    type: NODETYPE_SELECTMANY,
                    subtitle: 'Select a few options?',
                    expanded: false,
                    rstId: nextNodeId
                }
            case NODETYPE_SELECTONE:
                return {
                    title: NODETYPE_SELECTONE,
                    type: NODETYPE_SELECTONE,
                    subtitle: 'Select an option?',
                    expanded: false,
                    rstId: nextNodeId
                }
            case NODETYPE_GEO:
                return {
                    title: NODETYPE_GEO,
                    type: NODETYPE_GEO,
                    subtitle: 'Enter geo data?',
                    rstId: nextNodeId
                }
            case NODETYPE_IMAGE:
                return {
                    title: NODETYPE_IMAGE,
                    type: NODETYPE_IMAGE,
                    subtitle: 'Enter image data?',
                    rstId: nextNodeId
                }
            case NODETYPE_NUMBER:
                return {
                    title: NODETYPE_NUMBER,
                    type: NODETYPE_NUMBER,
                    subtitle: 'Enter a number?',
                    rstId: nextNodeId
                }
            case NODETYPE_TEXT:
                return {
                    title: NODETYPE_TEXT,
                    type: NODETYPE_TEXT,
                    subtitle: 'Enter some text?',
                    rstId: nextNodeId
                }
            case NODETYPE_OPTION:
                return {
                    title: NODETYPE_OPTION,
                    type: NODETYPE_OPTION,
                    subtitle: 'Option ' + nextNodeId,
                    rstId: nextNodeId
                }
            default:
                throw new Error('Node type not recognised: ' + uiNodeType)
        }
    } catch(err) {
        throw err
    }

}


export function getNewNodeId(reactSortableTree) {
    const nodeIds = []
    walk({
        treeData: reactSortableTree,
        getNodeKey: node => node.node.rstId,
        callback: node => nodeIds.push(node.node.rstId),
        ignoreCollapsed: false
    })
    nodeIds.sort(function(a, b) {
        return a - b;
    });
    if (nodeIds.length === 0) {
        return 1
    }
    return nodeIds[nodeIds.length - 1] + 1
}