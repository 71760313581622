import { FORMEDIT_UPDATE_CURRENTLY_EDITING, FORMEDIT_UPDATE_CURRENTLY_EDITING_ID, FORMEDIT_UPDATE_CURRENT_OPTION_LIST, FORMEDIT_UPDATE_OPTION_SELECTED, FORMEDIT_UPDATE_CURRENT_TITLE_AND_DESCRIPTION, FORMEDIT_SAVE_SUCCESS_RESPONSE, FORMEDIT_PARENT_ALERT_ID } from './formEdit.actions'

const initFormEdit = {
    current: null,
    currentId: null,
    currentTitle: '',
    currentDescription: '',
    currentActive:'',
    currentOptionNodes: {} // stores the user selected options on the form walkthrough. used to determine which inputs to show/hide on the walkthorugh
}

export function formEditReducer(state = initFormEdit, action) {
    switch(action.type) {
        case FORMEDIT_UPDATE_CURRENTLY_EDITING:
            return {...state, current: action.payload}
        case FORMEDIT_UPDATE_CURRENTLY_EDITING_ID:
            return {...state, currentId: action.payload}
        case FORMEDIT_UPDATE_CURRENT_OPTION_LIST:
            return {...state, currentOptionNodes: action.payload}
        case FORMEDIT_UPDATE_OPTION_SELECTED:
            // update state.currentOptionNodes.7.selected
            const newOptionSelected = action.payload
            const copy = JSON.parse(JSON.stringify(state.currentOptionNodes))
            Object.keys(newOptionSelected).forEach(optionId => {
                copy[optionId].selected = newOptionSelected[optionId].selected
            })
            return {...state, currentOptionNodes: copy}
        case FORMEDIT_UPDATE_CURRENT_TITLE_AND_DESCRIPTION:
            return {...state, currentTitle: action.payload.title, currentDescription: action.payload.description, currentActive: action.payload.active}
        case FORMEDIT_SAVE_SUCCESS_RESPONSE:
            return {...state, successfulPayloadSaveRequest: action.payload}
        case FORMEDIT_PARENT_ALERT_ID:
            return {...state, parentAlertId : action.payload}
        default:
            return state
    }
}