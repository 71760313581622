import { FORMEDIT_SAVE_PAYLOAD, FORMEDIT_CREATE_EDITABLE, FORMEDIT_SAVE_FORM, FORMEDIT_GENERATE_PROTOBUF, FORMEDIT_TRANSFORM_TO_FORM, FORMEDIT_FORM_TRANSFORMATION_COMPLETE,
    FORMEDIT_CREATE_NEW_NODE, FORMEDIT_DELETE_NODE, FORMEDIT_UPDATE_CURRENTLY_EDITING, FORMEDIT_UPDATE_QUESTION, FORMEDIT_SAVE_PAYLOAD_SUCCESS, FORMEDIT_SAVE_PAYLOAD_FAILURE,
    formEditUpdateCurrentlyEditing, formEditTransformToTreeComplete, formEditTransformToForm, formEditTransformToFormComplete, formSavePayloadSuccess,
    formEditUpdateCurrentlyEditingId, formEditUpdateCurrentOptionList, formEditUpdateTitleAndDescription, formFillPayload } from './formEdit.actions'
import { getTreeFromFlatData, getFlatDataFromTree, walk, map, addNodeUnderParent, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree'
import { isField, isOption, isParentRoot, isParentField, isParentOption, getField, getOption, generateNode, generateOption, createUiTreeNode, getNewNodeId } from './formEdit.helper'
import { errorsThrown } from './../errors/errors.actions'
import { formUpdate, formGenProto } from './form.actions'
import { getForm } from './form.selector'
import { NODETYPE_OPTION, NODETYPE_SELECTONE, NODETYPE_SELECTMANY } from '../../constants'
import { apiRequest } from './../api/api.action'

// const ROOT_ID = -1874 // need an id we will never use for another node
const ROOT_ID = null

// Used to transform the form from the back end into the form tree of the UI
export const formEditCreateEditableFormProcessor = ({ dispatch, getState }) => next => action => {
    next(action)
    //console.log(action.type, action.payload)
    if (action.type === FORMEDIT_CREATE_EDITABLE) {
        const selectedForm = getForm(action.payload)(getState())

        // transform back end form to ui form
        let myFields = selectedForm.key.fields

        // create node list and add a root node
        let uiNodeList = [{
            title: 'ROOT',
            id: ROOT_ID,
        }]

        // extract the options out of each field and turn them into nodes
        myFields.forEach(fd => {
            if (fd.options) {
                fd.options.forEach(option => {
                    option.title = 'option'
                    option.subtitle = option.label_display
                })
                uiNodeList = [...uiNodeList, ...fd.options]
            }
            uiNodeList.push(fd)
        })

        // First add an id for the UI to each node
        uiNodeList = uiNodeList.map((fo, i) => {
            fo.rstId = i + 1
            return fo
        })

        // Then find the parent of each node and add the parent id we just created
        // Also add other properies like title, subtitle etc
        uiNodeList = uiNodeList.map((fo, i, all) => {
            if (fo.id === ROOT_ID) { /** its root, do nothing **/ }
            else if (isField(fo)) {
                if (isParentField(fo)) {
                    fo.rstParentId = getField(fo.condition.field, all).rstId
                }
                if (isParentOption(fo)) {
                    fo.rstParentId = getOption(fo.condition.option, fo.condition.field, all).rstId
                }
                if (isParentRoot(fo)) {
                    fo.rstParentId = ROOT_ID
                }
                fo.title = fo.type
                fo.subtitle = fo.label_display
            } else if (isOption(fo)) {
                // if node is an option, parent has to be a field using field_id property
                fo.rstParentId = getField(fo.field_id, all).rstId
                fo.title = 'option'
                fo.type = 'option'
                fo.subtitle = fo.label_display
            } else {
                throw Error ('Error: node is not root, nor an option, nor a field')
            }

            fo.expanded = false
            return fo
        })


        const uiNodeTree = getTreeFromFlatData({
            flatData: uiNodeList,
            getKey: field => field.rstId,
            getParentKey: childField => childField.rstParentId,
            rootKey: ROOT_ID
        })
        dispatch(formEditTransformToTreeComplete({
            original: myFields,
            transformed: uiNodeTree
        }))
        dispatch(formEditUpdateCurrentlyEditing(uiNodeTree))
        dispatch(formEditUpdateCurrentlyEditingId(selectedForm.id))
        dispatch(formEditUpdateTitleAndDescription(selectedForm.key.name, selectedForm.key.description, selectedForm.key.active))
    }
}


export const formEditSaveFormProcessor = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_SAVE_FORM) {
        const formToSave = getState().editForm.current
        dispatch(formEditTransformToForm(formToSave))
    }
}

export const formSavePayloadSideEffect = ( store ) => next => action => {
    next(action)
    //console.log('formsavepayloadsideeffect called')
    //console.log(store)
    if (action.type === FORMEDIT_SAVE_PAYLOAD) {
        //console.log(action.type, action.payload,  formFillPayload)
        console.log("Payload to save")
        console.log(JSON.stringify(formFillPayload))
        store.dispatch(apiRequest(
            '/api/alerts/web',
            {
                method: 'POST',
                body: JSON.stringify(formFillPayload),
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            FORMEDIT_SAVE_PAYLOAD_SUCCESS, FORMEDIT_SAVE_PAYLOAD_FAILURE)
        )
        var myjson = JSON.stringify(formFillPayload, null, 4);
        console.log(myjson);
    }
}

export const formSavePayloadProcessor = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_SAVE_PAYLOAD_SUCCESS) {
        console.log('formSavePayloadProcessor called')
        console.log(action.payload)
        dispatch(formSavePayloadSuccess(true))
    }
}


export const formEditGenerateProtoBuf = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_GENERATE_PROTOBUF) {
        const formToSave = getState().editForm.current
        dispatch(formEditTransformToForm(formToSave))
    }
}

// Used to transform the form tree from the UI into the form structure of the back end
export const formEditTransformBackToForm = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_TRANSFORM_TO_FORM) {
        try {
            const myTree = action.payload

            let nextId = 1
            // create a field object on all field nodes (ie not 'option')
            const createFields = (item) => {
                //console.log(item)
                if (typeof item.node.type === 'undefined') console.log(item)
                else if (item.node.type !== 'option') {
                    const field = generateNode({
                        type: item.node.type,
                        description: item.node.subtitle,
                        id: nextId++,
                        parentOption: null,
                        parentField: null
                    })
                    item.node.field = field
                }
                return item.node
            }

            const newTreeWithFields = map({
                treeData: myTree,
                getNodeKey: node => node.rstId,
                ignoreCollapsed: false,
                callback: createFields
            })


            const addOptionsToFields = (item) => {
                if (typeof item.node.type === 'undefined') console.log(item)
                else if (item.node.type === 'selectOne' || item.node.type === 'selectMany') {
                    item.node.children && item.node.children.forEach((child) => {
                        if(child.type === 'option') {
                            const fieldId = item.node.field.id
                            const optionId = item.node.field.options.length + 1
                            const option = generateOption(optionId, fieldId, child.subtitle)
                            item.node.field.options.push(option)
                        }
                    })

                }
                return item.node
            }

            const newTreeWithOptionsInFields = map({
                treeData: newTreeWithFields,
                getNodeKey: node => node.rstId,
                ignoreCollapsed: false,
                callback: addOptionsToFields
            })


            const addParentIdInOptionNodes = (item) => {
                if (typeof item.node.type === 'undefined') console.log(item)
                else if (item.node.type === 'option') {
                    // Get the option_id and field_id from the option object stored in parent
                    // Why we do this?
                    // To pass the ids to any children. Then we can remove the option nodes because the back end likes it that way
                    const optionInParentField = item.parentNode.field.options.find(option => {
                        return option.label_display === item.node.subtitle
                    })
                    if (typeof optionInParentField === 'undefined') throw Error('Error: can not find option in parent field ' + JSON.stringify(item.node))
                    else {
                        item.node.optionLocation = {
                            option: optionInParentField.option_id,
                            field: optionInParentField.field_id
                        }
                    }
                }
                return item.node
            }

            const newTreeWithParentFieldIdInOptionNodes = map({
                treeData: newTreeWithOptionsInFields,
                getNodeKey: node => node.rstId,
                ignoreCollapsed: false,
                callback: addParentIdInOptionNodes
            })


            const addConditionsToFields = (item) => {
                if (typeof item.node.type === 'undefined') console.log(item)
                else if (item.node.type !== 'option') { // this is a field
                    if (item.parentNode === null) { // if its a child of root
                        item.node.field.condition = {}
                    } else if (item.parentNode.type === 'option') { // child of option
                        item.node.field.condition = {
                            option: item.parentNode.optionLocation.option,
                            field: item.parentNode.optionLocation.field
                        }
                    } else { // child of field
                        item.node.field.condition = {
                            option: null,
                            field: item.parentNode.field.id
                        }
                    }
                }
                return item.node
            }


            const newTreeWithConditionInFields = map({
                treeData: newTreeWithParentFieldIdInOptionNodes,
                getNodeKey: node => node.rstId,
                ignoreCollapsed: false,
                callback: addConditionsToFields
            })



            const backEndFormNodes = getFlatDataFromTree({
                treeData: newTreeWithConditionInFields,
                getNodeKey: node => node.rstId,
                ignoreCollapsed: false
            })

            const backEndFormData = backEndFormNodes
                .filter(item => item.node.field)
                .map(item => item.node.field)

            dispatch(formEditTransformToFormComplete({
                original: myTree,
                transformed: backEndFormData
            }))
        } catch(err) {
            dispatch(errorsThrown(err))
        }
    }
}

export const formEditGenerateProto = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_GENERATE_PROTOBUF) {
        const editingFormId = getState().editForm.currentId
        const editingFormTitle = getState().editForm.currentTitle
        const editingFormDescription = getState().editForm.currentDescription
        const editingFormActive = getState().editForm.currentActive
        const selectedForm = getForm(editingFormId)(getState())
        // put the new form in the object from the back end and try to save it
        const updateForm = {
            ...selectedForm.key,
            ...selectedForm,
        }
        delete updateForm.key
        updateForm.fields = action.payload.transformed
        updateForm._id = updateForm.id
        delete updateForm.id
        updateForm.name = editingFormTitle
        updateForm.description = editingFormDescription
        updateForm.active = editingFormActive
        dispatch(formGenProto(updateForm))
    }
}


export const formEditSaveToBackEnd = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_FORM_TRANSFORMATION_COMPLETE) {
        const editingFormId = getState().editForm.currentId
        const editingFormTitle = getState().editForm.currentTitle
        const editingFormDescription = getState().editForm.currentDescription
        const editingFormActive = getState().editForm.currentActive
        const selectedForm = getForm(editingFormId)(getState())
        // put the new form in the object from the back end and try to save it
        const updateForm = {
            ...selectedForm.key,
            ...selectedForm,
        }
        delete updateForm.key
        updateForm.fields = action.payload.transformed
        updateForm._id = updateForm.id
        delete updateForm.id
        updateForm.name = editingFormTitle
        updateForm.description = editingFormDescription
        updateForm.active = editingFormActive
        console.log('formEditSaveToBackEnd says', updateForm)
        dispatch(formUpdate(updateForm))
    }
}

// create a new node in the UI tree when user clicks on buttons
export const formEditCreateNewNode = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_CREATE_NEW_NODE) {
        // figure out what type of node we need
        // figure out where it goes (path)
        const nodeType = action.payload.nodeType
        // const nodePath = action.payload.nodePath
        const nodeParentKey = action.payload.nodeParentKey

        console.log("node parent key: "+nodeParentKey)

        // get current tree
        const currentTree = getState().editForm.current

        // figure out what rstId it will have
        const newNodeId = getNewNodeId(currentTree)

        console.log("node id: "+newNodeId);
        // create object of that type
        const newNode = createUiTreeNode(nodeType, newNodeId)

        // add node into current tree
        const newTree = addNodeUnderParent({
            treeData: currentTree,
            parentKey: nodeParentKey,
            expandParent: true,
            getNodeKey: node => node.node.rstId,
            newNode,
            addAsFirstChild: false // if parent key is null, add as child of root
        }).treeData

        // If its a selectOne or selectMany node, also create one child option
        if (nodeType === NODETYPE_SELECTONE || nodeType === NODETYPE_SELECTMANY) {
            const optionNodeId = getNewNodeId(newTree)
            const newOptionNode = createUiTreeNode(NODETYPE_OPTION, optionNodeId)
            const newOptionTree = addNodeUnderParent({
                treeData: newTree,
                parentKey: newNodeId,
                expandParent: true,
                getNodeKey: node => node.node.rstId,
                newNode: newOptionNode,
                addAsFirstChild: false // option will never be a chld of root node
            }).treeData
            dispatch(formEditUpdateCurrentlyEditing(newOptionTree))
        } else {
            // dispatch save method
            dispatch(formEditUpdateCurrentlyEditing(newTree))
        }
    }
}


export const formEditDeleteNode = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_DELETE_NODE) {
        const currentTree = getState().editForm.current
        const path = action.payload
        const newTree = removeNodeAtPath({
            treeData: currentTree,
            path,
            getNodeKey: node => node.node.rstId,
        })
        dispatch(formEditUpdateCurrentlyEditing(newTree))
    }
}

export const formEditUpdateQuestion = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_UPDATE_QUESTION) {
        const currentTree = getState().editForm.current
        const path = action.payload.path
        const newValue = action.payload.value
        const updateNode = (node) => {
            node.node.subtitle = newValue
            return node.node
        }
        const newTree = changeNodeAtPath({
            treeData: currentTree,
            path,
            newNode: updateNode,
            getNodeKey: node => node.node.rstId,
            ignoreCollapsed: false
        })
        dispatch(formEditUpdateCurrentlyEditing(newTree))
    }
}

// intercept the current form update action and update options in the option list
export const formEditUpdateOptionList = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FORMEDIT_UPDATE_CURRENTLY_EDITING) {
        const newFormTree = action.payload
        const newEnabledOptions = {}
        const oldEnabledOptions = getState().editForm.currentOptionNodes

        // get list of option nodes
        const optionList = []
        const addToOptionList = (node) => {
            if (node.node.type === NODETYPE_OPTION) {
                optionList.push(node)
            }
        }
        walk({
            treeData: newFormTree,
            getNodeKey: node => node.node.rstId,
            callback: addToOptionList,
            ignoreCollapsed: false
        })

        // turn option list into enabledOptions structure
        optionList.forEach(node => {
            let selected
            if (typeof oldEnabledOptions[node.node.rstId] === 'undefined') { // if we did not have the option before, create it as unchecked
                selected = false
            } else {
                selected = oldEnabledOptions[node.node.rstId].selected // if we did have option before, copy old checked value
            }
            newEnabledOptions[node.node.rstId] = {
                path: node.path,
                selected
            }
        })

        // save enabledOptions
        dispatch(formEditUpdateCurrentOptionList(newEnabledOptions))
    }
}


export const formEditMiddleware = [
    formEditCreateEditableFormProcessor,
    formEditSaveFormProcessor,
    formEditTransformBackToForm,
    formEditSaveToBackEnd,
    formEditCreateNewNode,
    formEditDeleteNode,
    formEditUpdateQuestion,
    formEditUpdateOptionList,
    formSavePayloadProcessor,
    formSavePayloadSideEffect
]