import { USERS_GET_ALL, USERS_GET_ALL_SUCCESS, USERS_GET_ALL_FAILURE, USERS_CREATE, USERS_CREATE_SUCCESS, USERS_CREATE_FAILURE, USERS_EDIT, USERS_EDIT_SUCCESS, 
    USERS_EDIT_FAILURE, USERS_DELETE, USERS_DELETE_SUCCESS, USERS_DELETE_FAILURE, 
    usersUpdateAll, usersGetAll, usersUpdateNetworkRequestCompleteFlag, userDeleteShowConfirmModal} from './users.actions'
import { apiRequest } from './../api/api.action'



export const usersGetAllSideEffect = (store) => next => action => {
    next(action)
    if (action.type === USERS_GET_ALL) {
        store.dispatch(apiRequest(
            '/users',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            },
            USERS_GET_ALL_SUCCESS, USERS_GET_ALL_FAILURE)
        )
    }
}

export const usersGetAllProcessor = (store) => next => action => {
    next(action)
    if (action.type === USERS_GET_ALL_SUCCESS) {
        store.dispatch(usersUpdateAll(action.payload.rows))
    }
}

export const usersCreateSideEffect = (store) => next => action => {
    next(action)
    if (action.type === USERS_CREATE) {
        store.dispatch(apiRequest(
            '/users',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(action.payload)
            },
            USERS_CREATE_SUCCESS, USERS_CREATE_FAILURE)
        )
    }
}

export const usersCreateProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === USERS_CREATE_SUCCESS) {
        dispatch(usersGetAll())
        dispatch(usersUpdateNetworkRequestCompleteFlag(true))
    }
}

export const usersEditSideEffect = (store) => next => action => {
    next(action)
    if (action.type === USERS_EDIT) {
        store.dispatch(apiRequest(
            '/api/users/' + action.payload._id,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify(action.payload)
            },
            USERS_EDIT_SUCCESS, USERS_EDIT_FAILURE),
            console.log(action.payload)
        )
    }
}

export const usersEditProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === USERS_EDIT_SUCCESS) {
        dispatch(usersUpdateNetworkRequestCompleteFlag(true))
        dispatch(usersGetAll())
    }
}

export const usersDeleteSideEffect = (store) => next => action => {
    next(action)
    if (action.type === USERS_DELETE) {
        store.dispatch(apiRequest(
            '/api/users/' + action.payload._id,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'DELETE'
            },
            USERS_DELETE_SUCCESS, USERS_DELETE_FAILURE)
        )
    }
}


export const usersDeleteProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === USERS_DELETE_SUCCESS) {
        dispatch(usersGetAll())
        dispatch(userDeleteShowConfirmModal(false, ""))
    }
}


export const usersMiddleware = [
    usersGetAllSideEffect,
    usersGetAllProcessor,
    usersCreateSideEffect,
    usersCreateProcessor,
    usersEditSideEffect,
    usersEditProcessor,
    usersDeleteSideEffect,
    usersDeleteProcessor
]