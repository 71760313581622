export const FORMEDIT_CREATE_EDITABLE = '[Form Edit] create editable form'

export const FORMEDIT_SAVE_FORM = '[Form Edit] save form'

export const FORMEDIT_TRANSFORM_TO_FORM = '[Form Edit] transform tree back to form'

export const FORMEDIT_NON_EXISTENT_FORM_SELECTED = '[Form Edit] non existent form selected'

export const FORMEDIT_UPDATE_CURRENTLY_EDITING = '[Form Edit] update currently editing'

export const FORMEDIT_UPDATE_CURRENTLY_EDITING_ID = '[Form Edit] update currently editing id'

export const FORMEDIT_TREE_TRANSFORMATION_COMPLETE = '[Form Edit] tree transformation complete'

export const FORMEDIT_FORM_TRANSFORMATION_COMPLETE = '[Form Edit] form transformation complete'

export const FORMEDIT_CREATE_NEW_NODE = '[Form Edit] create new node'

export const FORMEDIT_DELETE_NODE = '[Form Edit] delete node'

export const FORMEDIT_UPDATE_CURRENT_OPTION_LIST = '[Form Edit] update current option list'

export const FORMEDIT_UPDATE_OPTION_SELECTED = '[Form Edit] update option selected'

export const FORMEDIT_UPDATE_QUESTION = '[Form Edit] update question'

export const FORMEDIT_UPDATE_CURRENT_TITLE_AND_DESCRIPTION = '[Form Edit] update current title and description'

export const FORMEDIT_GENERATE_PROTOBUF = '[Form Edit] generate protocol buffer'

export const FORMEDIT_SAVE_PAYLOAD = '[Form Edit] save payload'
export const FORMEDIT_SAVE_PAYLOAD_SUCCESS = '[Form Edit] payload success'
export const FORMEDIT_SAVE_PAYLOAD_FAILURE = '[Form Edit] payload failure'
export const FORMEDIT_SAVE_SUCCESS_RESPONSE = '[Form Edit] payload success network request complete flag'
export const FORMEDIT_PARENT_ALERT_ID = '[Form Edit] Parent alert id'
////// user actions

export const formFillPayload = {
    'type' : 'alert',
    'imei': '',
    'momsn':'',
    'received_time': '',
    'transmit_time':'',
    'collect_time':'',
    'data':'',
    'ack':false,
    'ack_dt': false,
    'ack_usr': false,
    'verified': false,
    'verified_dt': false,
    'verified_usr': false,
    'action_req': false,
    'action_taken': false,
    'removed': false,
    'removed_usr': false,
    'removed_dt': false,
    'payload':{},
    'latitude': 0,
    'longitude':0,
    'accuracy':0,
    'community': "",
    'category':"",
    'isProduction':true,
    'via': 'WEB',
    'administrative_area_level_2':'',
    'administrative_area_level_1':'',
    'community_name_google':'',
    'case_manager':'',
    'case_assign_dt':0,
    'case_assign_by':0,
    'main_strategy_used': [],
    'assessed_actions': [],
    'assessed': false,
    'assessed_dt': '',
    'outcome_status':'',
    'outcome_usr':'',
    'outcome_dt':'',
    'outcome_report':'',
    'outcome_docs':[],
    'case_closed': false,
    'case_closed_dt': 0,
    'follow_up':[]
}

export const formEditCreateEditableForm = (formId) => ({
    type: FORMEDIT_CREATE_EDITABLE,
    payload: formId
});


export const formEditSaveForm = (form) => ({
    type: FORMEDIT_SAVE_FORM,
    payload: form
});

export const formFillForm = (formId) => ({
    type: FORMEDIT_CREATE_EDITABLE,
    payload: formId
});

export const formSavePayload = (payload) => ({
    type: FORMEDIT_SAVE_PAYLOAD,
    payload: payload
});
export const formSavePayloadSuccess = (success) => ({
    type: FORMEDIT_SAVE_SUCCESS_RESPONSE,
    payload: success
});

export const formEditParentAlertId = (id) => ({
    type: FORMEDIT_PARENT_ALERT_ID,
    payload: id
})

export const formEditGenerateProtoBuf = () => ({
    type: FORMEDIT_GENERATE_PROTOBUF
});

export const formEditCreateNewNode = (nodePathAndType) => ({
    type: FORMEDIT_CREATE_NEW_NODE,
    payload: nodePathAndType
});

export const formEditDeleteNode = (nodePath) => ({
    type: FORMEDIT_DELETE_NODE,
    payload: nodePath
});

export const formEditUpdateOptionSelected = (newOptionsSelected) => ({
    type: FORMEDIT_UPDATE_OPTION_SELECTED,
    payload: newOptionsSelected
})

export const formEditUpdateQuestion = (path, value) => ({
    type: FORMEDIT_UPDATE_QUESTION,
    payload: {
        path,
        value
    }
})

export const formEditUpdateTitleAndDescription = (title, description, active) => ({
    type: FORMEDIT_UPDATE_CURRENT_TITLE_AND_DESCRIPTION,
    payload: { title, description, active }
})

//////// internal actions

export const formEditNonExistentFormSelected = (formId) => ({
    type: FORMEDIT_NON_EXISTENT_FORM_SELECTED,
    payload: formId
});

export const formEditUpdateCurrentlyEditing = (formTree) => ({
    type: FORMEDIT_UPDATE_CURRENTLY_EDITING,
    payload: formTree
});

export const formEditUpdateCurrentlyEditingId = (formId) => ({
    type: FORMEDIT_UPDATE_CURRENTLY_EDITING_ID,
    payload: formId
});

export const formEditTransformToForm = (tree) => ({
    type: FORMEDIT_TRANSFORM_TO_FORM,
    payload: tree
});

export const formEditTransformToTreeComplete = (beforeAndAfter) => ({
    type: FORMEDIT_TREE_TRANSFORMATION_COMPLETE,
    payload: beforeAndAfter
});

export const formEditTransformToFormComplete = (beforeAndAfter) => ({
    type: FORMEDIT_FORM_TRANSFORMATION_COMPLETE,
    payload: beforeAndAfter
});

export const formEditUpdateCurrentOptionList = (optionNodesEnabled) => ({
    type: FORMEDIT_UPDATE_CURRENT_OPTION_LIST,
    payload: optionNodesEnabled
})
